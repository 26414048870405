import React from 'react'

import Layout from '../components/Layout'
import NotFound404 from '../components/404'

const NotFound = () => (
  <Layout
    className="layout--centered"
    title="Страница не найдена (404 Not Found)"
    content="К сожалению, запрашиваемая страница не найдена на сайте AppCraft"
    ogSiteName="AppCraft"
    ogTitle="Студия Appcraft | Разработка мобильных приложений для Android и iOS"
    ogDescription="Разработка мобильных продуктов от студии, которая создает и развивает собственные проекты. Эффективные решения для клиентов на iOS и Android, основанные на собственном опыте"
  >
    <NotFound404/>
  </Layout>
)

export default NotFound
