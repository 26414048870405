import React from 'react'

import './404.css'

import img404 from '../../images/404.svg'

const NotFound404 = ({ node }) => {
  return (
    <div className="notFound404">
      <img className="notFound404__image" src={img404} alt=""/>
      <p className="notFound404__text">Кажется, что-то пошло не так…</p>
      <a href="/" className="notFound404__back_button" rel="nofollow">
        <span className="">
          Вернуться на главную
        </span>
      </a>
    </div>
  )
}

export default NotFound404
